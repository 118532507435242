export const BaseAPIUrl = 'https://system.doneduardomarket.com/api/';
export const tuWalletApiUrl = 'https://app.tuwallet.com.ar/api/';
export const CloverAPIUrl = 'https://api.la.clover.com/';
//export const CloverAppID = 'Q72693KBFVHYC';
//export const CloverAppSecret = '08a49a4d-58e2-adba-6376-8e8bb01035ac';
export const CloverAppMerchant = 'Y2ERVYFY9B5S1';
export const CloverAccessToken = '4ca45af9-f521-40b1-0a9d-ce76ed6a4373';
export const PosnetAPIUrl = "https://cashie.solucionesinfinito.com/api/";
export const DevicesAPICashier = [
    {
        device: "CAJA1",
        api: "https://cashie.solucionesinfinito.com/caja1/",
        subApi: "https://api.doneduardomarket.com",
        deviceIp: "192.168.1.41",
        mpPosId: "QRCAJA1"
    },
    {
        device: "CAJA2",
        api: "https://cashie.solucionesinfinito.com/caja2/",
        subApi: "https://api.doneduardomarket.com",
        deviceIp: "192.168.1.42",
        mpPosId: "QRCAJA2"
    },
    {
        device: "CAJA3",
        api: "https://cashie.solucionesinfinito.com/caja3/",
        subApi: "https://api.doneduardomarket.com",
        deviceIp: "192.168.1.43",
        mpPosId: "QRCAJA3"
    },
    {
        device: "CAJA4",
        api: "https://cashie.solucionesinfinito.com/api4/",
        subApi: "https://api.doneduardomarket.com",
        deviceIp: "192.168.1.44",
        mpPosId: "QRCAJA4"
    }
];
export const MercadoCollectorID = "589482373";
export const MercadoStore = "doneduardomarket";
export const MercadoAccessToken = "APP_USR-2653978193741211-031309-a2811219c25781a0240c803f2d7609ad-589482373";
export const BillingCUIT = '30716322021';  // Production: 30716322021, Local: 20337436057
export const BillingAccessToken = "QDxi3Y2qUuZra3IFHApPBWtP5xxo7oe1QOh7JgeD4JY122wBjOcbgFBbZVNyNFjO";